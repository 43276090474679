
<template>
  <div class="body complaintForm">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="92px"
      label-position="left"
      class="demo-form"
    >
      <!-- 选择时间： -->
      <el-form-item label="投诉时间：" prop="time" class="time-picker">
        <el-date-picker
          cellClassName="timeRangePicker"
          v-model="form.time"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item label="投诉车辆：">
        <!-- <car-tree @getData="getVehIds" :limit="1"></car-tree> -->
        <el-select
          clearable
          filterable
          :filter-method="filterMethodFun"
          v-model="form.vehicleNo"
          placeholder="请选择"
        >
          <el-option
            v-for="item in vehicleOptions"
            :key="item.vehicleNo"
            :label="item.cph"
            :value="item.vehicleNo"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投诉事件：">
        <el-select
          clearable
          v-model="form.complaintEvent"
          placeholder="请选择投诉事件"
        >
          <el-option
            v-for="(item, key) in events"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理状态：">
        <el-select
          v-model="form.handleStatus"
          clearable
          placeholder="请选择处理状态"
        >
          <el-option label="未处理" :value="1"></el-option>
          <el-option label="已处理" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理时间：" prop="dealTime" class="time-picker">
        <el-date-picker
          cellClassName="timeRangePicker"
          v-model="form.dealTime"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="投诉人电话：" prop="complainantPhone">
        <el-input
          v-model="form.complaintTel"
          placeholder="请输入投诉人电话"
        ></el-input>
      </el-form-item>

      <el-form-item label="处理人：">
        <el-input
          v-model="form.handleMan"
          placeholder="请输入处理人"
        ></el-input>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button type="primary" size="small" @click="onSearch"
          >查询</el-button
        >
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import carTree from '@/components/carTree/carSelectTree.vue'
import {
  formatDate,
  checkTimeLimitDay
} from '@/common/utils/index'
import { queryVehicleFuzzy } from '@/api/lib/refreshAlarm.js'
export default {
  props: {
    timeLimit: {
      type: Number,
      default: 60
    },
    events: {
      type: Array,
      default: () => []
    }
  },
  // components: {
  //   carTree
  // },
  data () {
    // 不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (value) {
        if (new Date() < value[0] || new Date() < value[1]) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    let validateDate1 = (rule, value, callback) => {
      if (value) {
        if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
          callback(new Error(`只能查询${this.timeLimit}天内的数据`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      form: {
        time: '',
        dealTime: '',
        vehicleId: '',
        complaintEvent: '',
        complaintTel: '',
        handleStatus: null,
        handleMan: ''
      },
      vehicleOptions: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近两个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 59)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      rules: {
        time: [
          { validator: validateDateForward, trigger: 'change' },
          { validator: validateDate1, trigger: 'change' }
        ],
        dealTime: [
          { validator: validateDateForward, trigger: 'change' },
          { validator: validateDate1, trigger: 'change' }
        ],
        vehicleId: [
          { required: true, message: '请选择投诉车辆', trigger: 'change' }
        ],
        complaintEvent: [
          { required: true, message: '请选择投诉事件', trigger: 'blur' }
        ],
        handleStatus: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        complaintTel: [
          { required: true, message: '请输入乘客电话', trigger: 'blur' }
        ],
        handleMan: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 获取车辆数据
    // getVehIds (val) {
    //   this.$refs.form.clearValidate()
    //   this.form.vehicleId = val
    // },
    // 车辆模糊查询
    
    filterMethodFun (query) {
      if (query !== '') {
        setTimeout(() => {
          let data = { cph: query, system: sessionStorage.getItem("system") }
          queryVehicleFuzzy(data).then((res) => {
            if (res.code === 1000) {
              this.vehicleOptions = res.data
            }
          })
        }, 200)
      } else {
        this.vehicleOptions = []
      }
    },
    onSearch () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = { ...this.form }
          data.complaintStartTime = this.form.time ? formatDate(this.form.time[0]) : ''
          data.complaintEndTime = this.form.time ? formatDate(this.form.time[1]).split(' ')[0] + ' 23:59:59' : ''
          data.handleStartTime = this.form.dealTime ? formatDate(this.form.dealTime[0]) : ''
          data.handleEndTime = this.form.dealTime ? formatDate(this.form.dealTime[1]).split(' ')[0] + ' 23:59:59' : ''

          delete data.time
          delete data.dealTime
          this.$emit('getFormVal', data)
        }
      })
    }
  },
  created () { },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.el-textarea__inner {
  width: 300px;
}
.el-input__count {
  left: 260px;
  background: none;
}
</style>
