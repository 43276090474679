var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            "label-width": "110px",
            "label-position": "left",
            disabled: _vm.dealType == 1
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "投诉时间：", prop: "complaintTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  "picker-options": _vm.pickerOptions
                },
                model: {
                  value: _vm.addForm.complaintTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "complaintTime", $$v)
                  },
                  expression: "addForm.complaintTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择车辆：", prop: "vehicleId" } },
            [
              _c("car-tree", {
                attrs: { choosedCph: _vm.choosedCph },
                on: { getData: _vm.getVehIds }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "司机姓名：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入司机姓名" },
                model: {
                  value: _vm.addForm.driverName,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "driverName", $$v)
                  },
                  expression: "addForm.driverName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "乘客：", prop: "complaintMan" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入乘客姓名" },
                model: {
                  value: _vm.addForm.complaintMan,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "complaintMan", $$v)
                  },
                  expression: "addForm.complaintMan"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "乘客电话：", prop: "complaintTel" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入乘客电话" },
                model: {
                  value: _vm.addForm.complaintTel,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "complaintTel", $$v)
                  },
                  expression: "addForm.complaintTel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "投诉事件：", prop: "complaintEvent" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择投诉事件" },
                  model: {
                    value: _vm.addForm.complaintEvent,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "complaintEvent", $$v)
                    },
                    expression: "addForm.complaintEvent"
                  }
                },
                _vm._l(_vm.events, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.addForm.handleStatus !== 2,
                  expression: "addForm.handleStatus !== 2"
                }
              ],
              attrs: { label: "投诉内容：", prop: "complaintContent" }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 6,
                  maxlength: "150",
                  "show-word-limit": "",
                  placeholder: "请输入内容"
                },
                model: {
                  value: _vm.addForm.complaintContent,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "complaintContent", $$v)
                  },
                  expression: "addForm.complaintContent"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dealType == 1 && _vm.addForm.handleStatus == 2,
                  expression: "dealType == 1 && addForm.handleStatus == 2"
                }
              ]
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "处理状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.addForm.handleStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "handleStatus", $$v)
                        },
                        expression: "addForm.handleStatus"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "未处理", value: 1 } }),
                      _c("el-option", { attrs: { label: "已处理", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "处理时间：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addForm.handleTime,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "handleTime", $$v)
                      },
                      expression: "addForm.handleTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "处理人：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.handleMan,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "handleMan", $$v)
                      },
                      expression: "addForm.handleMan"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "处理结果：" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: "",
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.addForm.handleResult,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "handleResult", $$v)
                      },
                      expression: "addForm.handleResult"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }