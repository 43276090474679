
<template>
  <div class="body">
    <el-form
      :model="addForm"
      :rules="rules"
      ref="addForm"
      label-width="110px"
      label-position="left"
      class="demo-form dialog-form"
      :disabled="dealType == 1"
    >
      <el-form-item label="投诉时间：" prop="complaintTime">
        <el-date-picker
          type="date"
          placeholder="选择日期"
          v-model="addForm.complaintTime"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item label="选择车辆：" prop="vehicleId">
        <car-tree @getData="getVehIds" :choosedCph="choosedCph"></car-tree>
        <!-- <el-select
          filterable
          :filter-method="filterMethodFun"
          v-model="addForm.vehicleNo"
          placeholder="请选择"
        >
          <el-option
            v-for="item in vehicleOptions"
            :key="item.vehicleNo"
            :label="item.cph"
            :value="item.vehicleNo"
          ></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="司机姓名：">
        <el-input
          v-model="addForm.driverName"
          placeholder="请输入司机姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="乘客：" prop="complaintMan">
        <el-input
          v-model="addForm.complaintMan"
          placeholder="请输入乘客姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="乘客电话：" prop="complaintTel">
        <el-input
          v-model="addForm.complaintTel"
          placeholder="请输入乘客电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="投诉事件：" prop="complaintEvent">
        <el-select
          clearable
          v-model="addForm.complaintEvent"
          placeholder="请选择投诉事件"
        >
          <el-option
            v-for="(item, key) in events"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="投诉内容："
        prop="complaintContent"
        v-show="addForm.handleStatus !== 2"
      >
        <el-input
          type="textarea"
          :rows="6"
          maxlength="150"
          show-word-limit
          placeholder="请输入内容"
          v-model="addForm.complaintContent"
        ></el-input>
      </el-form-item>
      <!-- 处理结果 -->
      <div v-show="dealType == 1 && addForm.handleStatus == 2">
        <el-form-item label="处理状态：">
          <el-select v-model="addForm.handleStatus">
            <el-option label="未处理" :value="1"></el-option>
            <el-option label="已处理" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理时间：">
          <el-input v-model="addForm.handleTime"></el-input>
        </el-form-item>
        <el-form-item label="处理人：">
          <el-input v-model="addForm.handleMan" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="处理结果：">
          <el-input
            type="textarea"
            autosize
            placeholder="请输入内容"
            v-model="addForm.handleResult"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { checkPhone, checkLimitDate, checkName, formatDate } from '@/common/utils/index.js'
import carTree from '@/components/carTree/carSelectTree.vue'
import { queryVehicleFuzzy } from '@/api/lib/refreshAlarm.js'
import { currentDriver,addComplaint,getComplaintHandleById,updateBatchComplaintHandle } from '@/api/lib/taxi-api.js'
export default {
  props: {
    // 处理类型 1 详情 2 修改 0 新增
    dealType: {
      type: Number
    },
    events: {
      type: Array,
      default: () => []
    },
    itemId: {
      type: [Number, String]
    }

  },
  components:{
    carTree
  },
  data () {
    return {
      choosedCph: '',
      addForm: {
        complaintTime: new Date(),
        vehicleId: '',
        complaintVehicle: '',
        driverName: '',
        complaintEvent: '',
        complaintMan: '',
        complaintTel: '',
        complaintContent: ''
      },
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        complaintTime: [
          { required: true, message: '请选择投诉时间', trigger: 'blur' }
        ],
        vehicleId: [
          { required: true, message: '请选择投诉车辆', trigger: 'change' }
        ],
        driverName: [
          { required: true, message: '请输入司机姓名', trigger: 'blur' }
        ],
        complaintEvent: [
          { required: true, message: '请选择投诉事件', trigger: 'blur' }
        ],
        complaintMan: [
          { required: true, message: '请输入乘客姓名', trigger: 'blur' },
          { validator: checkName, trigger: 'blur' }

        ],
        complaintTel: [
          { required: true, message: '请输入乘客电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        complaintContent: [
          { required: true, message: '请输入投诉内容', trigger: 'blur' }
        ]
      },
      vehicleOptions: [],
    }
  },
  methods: {
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.addForm.clearValidate()
      this.addForm.vehicleId = val;
      //获取到司机姓名
      // currentDriver()
    },
    filterMethodFun (query) {
      if (query !== '') {
        setTimeout(() => {
          let data = { cph: query, system: sessionStorage.getItem("system") }
          queryVehicleFuzzy(data).then((res) => {
            if (res.code === 1000) {
              this.vehicleOptions = res.data
            }
          })
        }, 200)
      } else {
        this.vehicleOptions = []
      }
    },
    // 新增/保存用户
    onSave () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$emit('onLoading', true)
          let data = {
            complaintTime: formatDate(this.addForm.complaintTime),
            vehicleId: this.addForm.vehicleId,
            // complaintVehicle: this.addForm.complaintVehicle,
            driverName: this.addForm.driverName,
            complaintMan: this.addForm.complaintMan,
            complaintTel: this.addForm.complaintTel,
            complaintEvent: this.addForm.complaintEvent,
            complaintContent: this.addForm.complaintContent
          }
          console.log('data',data)
          if (this.itemId === null) {
            addComplaint(data).then(res => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)                
              }
              this.$emit('onLoading', false)
            })
          } else {
            data.complaintHandleId = this.itemId
            addComplaint(data).then(res => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    }
  },
  created () { 
    
  },
  mounted () {
    if (this.itemId) {
      getComplaintHandleById(this.itemId).then(res => {
        if (res.code === 1000) {
          this.addForm = { ...res.data }
          this.choosedCph = res.data.cph
          this.addForm.complaintEvent =  `${res.data.complaintEvent}`
          delete this.addForm.createTime
          delete this.addForm.updateTime
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-textarea__inner {
  width: 300px;
}
.el-input__count {
  left: 260px;
  background: none;
}
</style>
