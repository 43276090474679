import service from './request'
//接口前缀
const BASE_PREFIX = '/report';
/* ----------------- 业务报表 ---------------------- */
//车辆服务统计查询
const queryVehicleDataByList = data => {
  return service({
    url: `${BASE_PREFIX}/serviceStatistics/queryVehicleDataByPage`,
    method: 'post',
    data
  })
}
// 驾驶员统计报表查询
const queryDriverDataList = data => {
  return service({
    url: `${BASE_PREFIX}/serviceStatistics/queryDriverDataByPage`,
    method: 'post',
    data
  })
}
//营运明细查询（车辆、驾驶员通用）
const queryServiceDataDetail = data => {
  return service({
    url: `${BASE_PREFIX}/serviceStatistics/queryServiceDataDetail`,
    method: 'post',
    data
  })
}
//登退签明细查询
const queryLoginLogDetail = data => {
  return service({
    url: `${BASE_PREFIX}/serviceStatistics/queryLoginLogDetail`,
    method: 'post',
    data
  })
}
//在线率分页查询
const statisticsVehicleOnlineList = data => {
  return service({
    url: `${BASE_PREFIX}/online/statisticsVehicleOnline`,
    method: 'post',
    data
  })
}
//运营数据统计
const queryByConditionList = data => {
  return service({
    url: `/taxi/operationalStatistics/queryByCondition`,
    method: 'post',
    data
  })
}
/* ----------------- 客户服务 ---------------------- */
//服务评价
const queryCommentPagedList = data => {
  return service({
    url: `/taxi/operateData/queryPagedList`,
    method: 'post',
    data
  })
}
//投诉管理查询
const queryComplaintList = data => {
  return service({
    url: `/taxi/complaint/queryPagedList`,
    method: 'post',
    data
  })
}
//投诉管理，关联查询驾驶员
const currentDriver = (companyId,vehicleId,data) => {
  return service({
    url: `/base/driver/currentDriver/${companyId}/${vehicleId}`,
    method: 'get',
    data
  })
}

//录入投诉
const addComplaint = data => {
  return service({
    url: `/taxi/complaint/insertOrUpdate`,
    method: 'post',
    data
  })
}
//投诉详情
const getComplaintHandleById = data => {
  return service({
    url: `/taxi/complaint/queryById/${data}`,
    method: 'get',
    data
  })
}
//投诉处理/修改
const handleComplaint = data => {
  return service({
    url: `taxi/complaint/handleComplaint`,
    method: 'post',
    data
  })
}

//实物查找
const lostFindList = data => {
  return service({
    url: `/taxi/lostFind/queryList`,
    method: 'post',
    data
  })
}

//人脸识别结果
const faceSignList = data => {
  return service({
    url: `/base/faceSign/pageList`,
    method: 'post',
    data
  })
}
//识别照片查询
const faceSignMedias = data => {
  return service({
    url: `/base/faceSign/medias`,
    method: 'get',
    data
  })
}

export {
  queryVehicleDataByList,
  queryDriverDataList,
  queryServiceDataDetail,
  queryLoginLogDetail,
  statisticsVehicleOnlineList,
  queryByConditionList,
  queryCommentPagedList,
  queryComplaintList,
  currentDriver,
  addComplaint,
  getComplaintHandleById,
  handleComplaint,
  lostFindList,
  faceSignList,
  faceSignMedias
}

/* ----------------- 数据看板 ---------------------- */
//收入情况
const queryIncomeCount = data => {
  return service({
    url: `${BASE_PREFIX}/dataNavigation/queryIncomeCount`,
    method: 'get',
    data
  })
}
//营收合计
const queryOperateTotal = data => {
  return service({
    url: `${BASE_PREFIX}/dataNavigation/queryOperateTotal`,
    method: 'get',
    data
  })
}
//车辆收入排行
const queryOperatingAmountTop = data => {
  return service({
    url: `${BASE_PREFIX}/dataNavigation/queryOperatingAmountTop`,
    method: 'get',
    data
  })
}
//载客率统计
const queryOccupancyRate = data => {
  return service({
    url: `${BASE_PREFIX}/dataNavigation/queryOccupancyRate`,
    method: 'get',
    data
  })
}
//车辆报警排行
const queryVehicleNoGroupAlarm = data => {
  return service({
    url: `${BASE_PREFIX}/dataNavigation/queryVehicleAlarmRanking`,
    method: 'get',
    data
  })
}
//报警类型排行
const queryAlarmTypeGroupAlarm = data => {
  return service({
    url: `${BASE_PREFIX}/dataNavigation/queryAlarmTypeRank`,
    method: 'get',
    data
  })
}
//报警处理情况
const queryDisposeStatusGroupAlarm = data => {
  return service({
    url: `${BASE_PREFIX}/dataNavigation/queryDisposeStatusGroupAlarm`,
    method: 'get',
    data
  })
}
//上车热力分布图
const queryIncomeByDistributionMap = data => {
  return service({
    url: `${BASE_PREFIX}/dataNavigation/queryIncomeByDistributionMap`,
    method: 'get',
    data
  })
}



export{
  queryIncomeCount,
  queryOperateTotal,
  queryOperatingAmountTop,
  queryOccupancyRate,
  queryVehicleNoGroupAlarm,
  queryAlarmTypeGroupAlarm,
  queryDisposeStatusGroupAlarm,
  queryIncomeByDistributionMap
}