
<template>
  <div class="complaint-management" ref="complaintManagement">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small">新增</el-button>
    </div>
    <div class="form-area" ref="form">
      <seachForm :events="events" @getFormVal="onSubmit">
        <!-- <el-button type="primary" size="small">导出</el-button> -->
        <el-button type="primary" size="small" @click="onAddComplaint"
          >投诉录入</el-button
        >
      </seachForm>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="complaintTime"
        label="投诉时间"
        width="170"
        :show-overflow-tooltip="true"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="投诉车辆"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="driverName"
        label="司机姓名"
        width="150"
        :formatter="formatNull"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="complaintEventName"
        label="投诉事件"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="complaintMan"
        label="投诉人"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="complaintTel"
        label="投诉人电话"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="handleStatus"
        label="处理状态"
        :show-overflow-tooltip="true"
        ><template slot-scope="scope">
          <span>{{ scope.row.handleStatus === 1 ? "未处理" : "已处理" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="handleTime"
        label="处理时间"
        width="170"
        :formatter="formatNull"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="handleMan"
        label="处理人"
        :formatter="formatNull"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            @click="onComplaintDetail(scope.row)"
            type="text"
            size="small"
            sort="primary"
            >详情</el-button
          >
          <el-button
            v-if="scope.row.handleStatus == 1"
            @click="onComplaintUpdate(scope.row)"
            type="text"
            size="small"
            sort="primary"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.handleStatus == 1"
            type="text"
            size="small"
            sort="danger"
            @click="onDealComplaint(scope.row)"
            >处理</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <!-- 投诉录入 ||投诉详情-->
    <el-dialog
      v-if="dialogVisible"
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <addForm
        ref="addComplaint"
        :dealType="dealType"
        :itemId="itemId"
        :events="events"
        @onLoading="onLoading"
        @close="onDialogClose"
      />
      <span slot="footer" class="dialog-footer" v-if="dealType !== 1">
        <el-button
          type="primary"
          size="small"
          @click="onSave"
          :loading="editLoading"
          >提交</el-button
        >
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
    <!-- 处理投诉 -->
    <el-dialog
      v-if="detailDialogVisible"
      title="投诉详情"
      :visible.sync="detailDialogVisible"
      width="30%"
      ><el-form
        :model="dealForm"
        :rules="rules"
        ref="dealForm"
        label-width="100px"
        label-position="left"
        class="demo-form dialog-form deal-form"
      >
        <el-form-item label="处理结果：" prop="handleResult">
          <el-input
            type="textarea"
            :rows="6"
            maxlength="400"
            show-word-limit
            placeholder="请输入内容"
            v-model="dealForm.handleResult"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="onDeal"
          :loading="dealLoading"
          >提交</el-button
        >
        <el-button
          type="default"
          size="small"
          @click="detailDialogVisible = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import addForm from '../components/addForm'
import { queryDictByParentCode } from '@/api/lib/api.js'
import seachForm from '../components/form.vue'
import { formatDay } from '@/common/utils/index.js'
import { queryComplaintList,handleComplaint } from '@/api/lib/taxi-api.js'

export default {
  name: 'complaintManagement',
  components: {
    seachForm,
    addForm
  },
  data () {
    return {
      itemId: null,
      tableHeight: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10
      },
      dealForm: {
        handleResult: ''
      },
      rules: {
        handleResult: [
          { required: true, message: '请输入投诉处理内容', trigger: 'blur' }
        ]
      },
      total: 0,
      dealType: 0,
      dialogVisible: false,
      detailDialogVisible: false,
      title: '',
      events: [],
      editLoading: false,
      dealLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.complaintManagement.clientHeight
      let buttonsHeight = this.$refs.functionButtons.clientHeight
      let formHeight = this.$refs.form.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight = wholeHeight - 16 - buttonsHeight - 16 - formHeight - paginationHeight - 10
    },
    // 提交
    onSubmit (value) {
      this.form = { ...this.form, ...value }
      this.form.currentPage = 1
      this.getTableData()
    },
    getTableData () {
      queryComplaintList(this.form).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg)
        }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getTableData()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getTableData()
    },
    // 投诉信息详情
    onComplaintDetail (row) {
      this.dealType = 1
      this.itemId = row.complaintHandleId
      this.title = '投诉详情'
      this.dialogVisible = true
    },
    // 修改投诉信息
    onComplaintUpdate (row) {
      this.dealType = 2
      this.itemId = row.complaintHandleId
      this.title = '修改投诉信息'
      this.dialogVisible = true
    },
    // 点击处理投诉录入
    onDealComplaint (row) {
      this.itemId = row.complaintHandleId
      this.detailDialogVisible = true
    },
    // 新增投诉录入
    onAddComplaint () {
      this.dealType = 0
      this.itemId = null
      this.title = '投诉录入'
      this.dialogVisible = true
    },
    /** 数据为空默认显示- */
    formatNull (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : cellValue
    },
    /** 时间默认显示年月日 */
    formatTime (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : formatDay(new Date(cellValue))
    },
    // 新增/修改角色
    onSave () {
      this.$refs.addComplaint.onSave()
    },
    onLoading (flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      if (this.dealType !== 1) {
        if (flag) {
          if (this.title.indexOf('录入') > -1) {
            this.onSubmit()
          } else {
            this.getTableData()
          }
        }
      }
      this.dialogVisible = false
      this.editLoading = false
    },
    /** 投诉处理 */
    onDeal () {
      this.$refs.dealForm.validate(valid => {
        if (valid) {
          this.dealLoading = true
          let data = {
            complaintHandleId: this.itemId,
            handleResult: this.dealForm.handleResult
          }
          handleComplaint(data).then(res => {
            if (res.code === 1000) {
              this.$message.success('处理成功')
              this.getTableData()
            } else {
              this.$message.error(res.msg)
            }
            this.detailDialogVisible = false
            this.dealLoading = false
          })
        }
      })
    },
    // 获取投诉事件类型类型
    getEventTypeList () {
      queryDictByParentCode({ parentCode: 'ZJCTSE' }).then(res => {
        if (res.code === 1000) {
          this.events = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  created () { 
    this.getEventTypeList()
   },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
      this.getTableData()
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.complaint-management{
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-taxi {
  .complaint-management{
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
.add {
  height: 100%;
  padding: 2vh 3vh;
}

</style>
